import { useQuery } from "@tanstack/react-query"
import axios, { AxiosResponse } from "axios"
import type { AuthUser } from "types/next-auth"

type Organization = AuthUser["organizations"][0]

export default function useOrganizations() {
  const { data } = useQuery({
    queryKey: ["organizations"],
    queryFn: async () => {
      const response: AxiosResponse<Organization[]> = await axios.get("/api/content/getOrganizations")
      return response.data
    },
    // Cache the data for 5 minutes
    staleTime: 5 * 60 * 1000,
  })

  return data
}
